<template>
  <el-menu
      :default-active="active()"
      class="el-menu-demo el-header"
      mode="horizontal"
      menu-trigger="click"
      :router="true"
      :ellipsis="false"
      v-if="!isLoading"
  >
    <el-menu-item v-if="'dashboard' in featuresKeys" index="/" :class="{'is-active' : activeIndex === 'dashboard'}">
      الرئيسية
    </el-menu-item>
    <el-menu-item v-if="'breakingNews' in featuresKeys" index="/add-breaking-news"
                  :class="{'is-active' : activeIndex === 'add-breaking-news'}">خبر عاجل
    </el-menu-item>
    <el-menu-item v-if="'news' in featuresKeys" index="/add-news" :class="{'is-active' : activeIndex === 'add-news'}">
      خبر جديد
    </el-menu-item>
    <el-menu-item v-if="'albums' in featuresKeys" index="/create-album" :class="{'is-active' : activeIndex === ''}">
      انشاء البوم الصور
    </el-menu-item>
    <el-menu-item v-if="'playList' in featuresKeys" index="/create-playList"
                  :class="{'is-active' : activeIndex === ''}">انشاء قائمة تشغيل
    </el-menu-item>
    <el-sub-menu index="2" class="position-end" :class="{'is-active' : activeIndex === ''}">
      <template #title>
        <img :src="APP_URL + currentUser?.image ?? '/media/avatars/blank.png'" class="user-avatar" alt=""/>
        <span class="mx-2 d-md-inline d-none">{{ currentUser?.name ?? '' }}</span>
      </template>
      <el-menu-item class="justify-content-center" index="/profile">
        الملف الشخصي
      </el-menu-item>
      <el-divider class="my-1 mx-auto" />
      <el-menu-item class="justify-content-center" index="/change-password">
        تغيير كلمة المرور
      </el-menu-item>
      <el-divider class="my-1 mx-auto" />
      <el-menu-item class="justify-content-center" index="/login">
        <button class="btn text-danger" type="button" @click="handleLogout">
          تسجيل الخروج
        </button>
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {computed, onMounted, ref} from "vue";

const router      = useRouter();
const route       = useRoute()
const store       = useStore()
const activeIndex = ref(route.name)

onMounted(() => store.dispatch('fetchFeatures'))

const APP_URL = computed(()=> process.env.VUE_APP_IMAGE_REMOTE_URL)
const isLoading    = computed(() => store.getters.isLoading)
const featuresKeys = computed(() => store.getters.featuresKeys)
const currentUser  = computed(() => store.getters.getCurrentUser)
const active       = () => {
  activeIndex.value = route.name
  return route.name
}

const handleLogout = async () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('type_id')
  await store.dispatch('fetchUser', null)
  await store.dispatch('resetFeatures')
  await store.dispatch('resetCategories')
  await router.push('/login')
}
</script>

<style>
.el-menu--horizontal > .el-menu--popup {
  min-width: 150px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  overflow: hidden;
}

.el-menu--horizontal .el-menu--popup {
  padding-top: 0;
}
.el-divider.el-divider--horizontal {
  width: 96%;
}
.el-menu--horizontal .el-menu--popup .el-menu-item,
.btn.text-danger {
  font-weight: 600;
  font-size: 13px;
}
</style>
